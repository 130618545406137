import { GatsbySeo } from 'gatsby-plugin-next-seo'

import { Heading } from '@monobits/components'

const NotFoundPage = () => (
  <>
    <GatsbySeo title="404 - Page" description="Oops this page doesn't exist" />
    <Heading as="h1">404</Heading>
  </>
)

export default NotFoundPage
